import React, { useEffect } from "react";
import * as Icons from "@wix/wix-ui-icons-common";
import {
  Box,
  Card,
  WixDesignSystemProvider,
  Text,
  Page,
  Loader,
  TableActionCell,
  Table,
  TableToolbar,
  Button,
  CopyClipboard,
  TextButton,
  EmptyState,
} from "@wix/design-system";
import "@wix/design-system/styles.global.css";
import { ReactTagManager } from "react-gtm-ts";
import * as basePlatform from "@certifiedcode/base";

import { dashboard } from "@wix/dashboard";
import { createClient } from "@wix/sdk";

const FREE_VERSION_LIMIT = 10;

const tagManagerArgs = {
  code: "GTM-WZQPMC7",
};
function inIframe() {
  try {
    return window.self !== window.top;
  } catch (e) {
    return true;
  }
}

function App() {
  ReactTagManager.init(tagManagerArgs);
  const [client, setClient] = React.useState(null as any);
  const token = new URLSearchParams(window.location.search).get("token");
  const instance = new URLSearchParams(window.location.search).get("instance");
  const [isUpgraded, setIsUpgraded] = React.useState(false);
  const [instanceData, setInstanceData] = React.useState({
    instance: {
      isFree: true,
      availablePlans: [],
      instanceId: "",
    },
  } as any);
  const [plans, setPlans] = React.useState([] as any);
  const [currentPlanId, setCurrentPlanId] = React.useState("");
  const [paymentMethodId, setPaymentMethodId] = React.useState("ecpay");

  const [showSuccess, setShowSuccess] = React.useState(false);
  const [showError, setShowError] = React.useState(false);
  const [requests, setRequests] = React.useState([] as any);
  const [products, setProducts] = React.useState([] as any);
  const [loading, setLoading] = React.useState(true);

  const baseUrl = "https://certifiedcode.wixsite.com/request-quote/_functions";

  const currentPlan = plans.find((plan: any) => plan.id === currentPlanId);

  const currentConfig = currentPlan?.config?.config || {};

  useEffect(() => {
    try {
      if (inIframe()) {
        var c = createClient({
          host: dashboard.host(),
          auth: dashboard.auth(),
          modules: {
            dashboard,
          },
        });
        setClient(c);
      }
    } catch (error) {
      throw error;
    }
  }, []);

  useEffect(() => {
    basePlatform.initialize({
      baseURL: "https://certifiedcode.wixsite.com/request-quote/_functions", // Replace with your API base URL
      applicationID: "8647e1b7-0ac5-47e8-85a4-d6359117e5f0", // Replace with your application ID
    });
    basePlatform.setIdentity(instance);
    basePlatform.setInstance(setInstanceData, setIsUpgraded);
    getRequests();
  }, []);

  function getRequests() {
    fetch(baseUrl + "/requests", {
      headers: {
        Authorization: instance!,
      },
    })
      .then((res) => res.json())
      .then((data) => {
        if (data.error) {
          setShowError(true);
        } else {
          setLoading(false);
          setProducts(data.products);
          setRequests(data.allRequests);
        }
      });
  }

  if (token) {
    return (
      <WixDesignSystemProvider features={{ newColorsBranding: true }}>
        <Page height="100vh">
          <Page.Content>
            <Box height={"90vh"} direction="vertical" verticalAlign="middle">
              <Loader text="Loading" />
            </Box>
          </Page.Content>
        </Page>
      </WixDesignSystemProvider>
    );
  }

  // const primaryAction = { text: "View Contact", onClick: () => {},  };

  const columns = [
    {
      title: "Name",
      render: (row: any) => <Box direction="vertical">
      <Text size="small">{[row.firstName, row.lastName].join(" ")}</Text>
      <Text size="tiny" secondary light>
        {row.content}
      </Text>
    </Box>,
      width: 100,
    },
    {
      title: "Email",
      render: (row: any) => (
        <CopyClipboard value={row.email}>
          {({ isCopied, copyToClipboard, reset }) => (
            <Box width="260" align="space-between">
              <Text size="small">{row.email}</Text>
              <TextButton
                size="small"
                onClick={() => (isCopied ? reset() : copyToClipboard())}
              >
                {!isCopied ? "Copy" : "Copied!"}
              </TextButton>
            </Box>
          )}
        </CopyClipboard>
      ),
      width: 200,
    },
    {
      title: "Phone",
      render: (row: any) => (
        <CopyClipboard value={row.phone}>
          {({ isCopied, copyToClipboard, reset }) => (
            <Box width="260" align="space-between">
              <Text size="small">{row.phone}</Text>
              <TextButton
                size="small"
                onClick={() => (isCopied ? reset() : copyToClipboard())}
              >
                {!isCopied ? "Copy" : "Copied!"}
              </TextButton>
            </Box>
          )}
        </CopyClipboard>
      ),
      width: 200,
    },
    {
      title: "Products",
      render: (row: any) =>
        (
          (typeof row.productIds === "string"
            ? JSON.parse(row.productIds)
            : row.productIds) || []
        )
          .map(
            (id: string) =>
              products.find((product: any) => product._id === id)?.name
          )
          .join(", "),
      width: 200,
    },
    {
      render: (row: any) => (
        <TableActionCell
          size="small"
          // primaryAction={primaryAction}
          secondaryActions={[
            {
              icon: <Icons.User />,
              text: "View Contact",
              onClick: () => {
                client.dashboard.navigate({
                  pageId: "bdd09dca-7cc9-4524-81d7-c9336071b33e",
                  relativeUrl: `/view/${row.contactId}`,
                });
              },
            },
          ]}
          moreActionsTooltipText="More actions"
        />
      ),
      stickyActionCell: true,
      width: 30,
    },
  ];

  return (
    <WixDesignSystemProvider features={{ newColorsBranding: true }}>
      <Page>
        <Page.Header
          title="Request Quote for Stores"
          actionsBar={
            <Button
              prefixIcon={<Icons.PremiumFilled />}
              skin={isUpgraded ? "premium-light" : "premium"}
              as="a"
              href={basePlatform.getUpgradeUrl()}
              target="_blank"
            >
              {isUpgraded ? "Manage Plan" : "Upgrade"}
            </Button>
          }
        />
        <Page.Content>
          {requests.length > 0 ? (
            <Card>
              <Table
                stickyColumns={1}
                data={isUpgraded ? requests : requests.slice(0, FREE_VERSION_LIMIT)}
                // @ts-ignore
                columns={columns}
              >
                <TableToolbar>
                  <TableToolbar.ItemGroup position="start">
                    <TableToolbar.Item>
                      <Text size="small">{`${requests.length} requests`}</Text>
                    </TableToolbar.Item>
                  </TableToolbar.ItemGroup>
                </TableToolbar>
                <Table.Content />
              </Table>
              {requests.length > FREE_VERSION_LIMIT && <Card.Divider />}
              {requests.length > FREE_VERSION_LIMIT && (
                <Card.Content>
                  <EmptyState
                    title={`View all ${requests.length} requests with Premium`}
                    subtitle="Upgrade now to view all requests"
                  >
                    <Button
                      prefixIcon={<Icons.PremiumFilled />}
                      skin="premium"
                      as="a"
                      href={basePlatform.getUpgradeUrl()}
                      target="_blank"
                    >
                      Upgrade
                    </Button>
                  </EmptyState>
                </Card.Content>
              )}
            </Card>
          ) : loading ? (
            <Loader
              status={showError ? "error" : undefined}
              statusMessage={
                showError ? "Contact support if issue persist." : undefined
              }
            />
          ) : (
            <EmptyState
              theme="page"
              title="Get your first request now"
              subtitle="Add Request Quote widget by: Open site editor > Product page > Tap on product page and click Plugins icon > Add Request Quote widget"
            />
          )}
        </Page.Content>
      </Page>
    </WixDesignSystemProvider>
  );
}

export default App;
